import React, { useEffect, useState } from 'react';
import type { GetServerSideProps, NextPage } from 'next';
import useTypedSelector from '@/hooks/useTypedSelector';
import useTypedDispatch from '@/hooks/useTypedDispatch';
import styled from 'styled-components';
import { SwiperSlide } from 'swiper/react';
import { getTariffs } from '@/store/Tariffs';
import Slider from '@/components/ui/SliderContainer';
import MainBanners from '@/components/ui/MainBanners';
import InfoSection, { InfoButton, ShowButton } from '@/components/ui/InfoSection';
import Slider3D from '@/components/ui/Slider3D';
import CoachesCatalog from '@/components/ui/CoachesCatalog';
import CoachesResultsItem from '@/components/ui/CoachesResults';
import FlexSection from '@/components/ui/FlexSection';
import AboutUsSection from '@/components/ui/AboutUsSection';
import AthletesSection from '@/constants/AthletesSection';
import AboutUsItem from '@/constants/AboutUs';
import {
  CoachesCatalogBtn,
  CoachesCatalogStyle,
  CoachesCatalogTitle
} from '@/constants/CoachesCatalog';
import CoachesResultItems, {
  CoachesResultStyle,
  CoachesResultTitle
} from '@/constants/CoachesResult';
import { Slider3DItem } from '@/interfaces/Slider3D';
import UserRoleType from '@/enums/userRole';
import { getCoaches } from '@/store/Coaches';
import { CoachItem } from '@/store/Coaches/types';
import RunningString from '@/components/ui/RunningString';
import FrequentlyAskedQuestions from '@/components/ui/FAQ';
import { getQuestions } from '@/store/FAQ';
import PreviewComponent from '@/components/ui/PreviewComponent';

const Home: NextPage = () => {
  const dispatch = useTypedDispatch();
  const { auth } = useTypedSelector((state) => state.auth);
  const { role } = useTypedSelector((state) => state.user);
  const { tariffs } = useTypedSelector((state) => state.tariffs);
  const { coaches } = useTypedSelector((state) => state.coaches);
  const { questions } = useTypedSelector((state) => state.FAQ);
  const [authStatus, setAuthStatus] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getTariffs());
    dispatch(getCoaches({
      page: 1,
      loader: false
    }));
    dispatch(getQuestions());
  }, []);

  useEffect(() => {
    if (auth) {
      setAuthStatus(true);
    }
  }, [auth]);

  //Рендер Слайдера для показа тренеров
  const renderCoachesCatalogItems = (
    items: CoachItem[]
  ): React.ReactElement[] => {
    return items.map(
      ({ id, experience, image, user, short_description }: CoachItem): React.ReactElement => (
        <SwiperSlide key={id} style={{ position: 'relative', overflow: 'hidden' }}>
          <CoachesCatalog id={id} user={user} image={image} experience={experience}
                          short_description={short_description} />
        </SwiperSlide>
      )
    );
  };

  //Рендер Слайдера для показа результата тренировок
  const renderCoachesResultItems = (
    items: Slider3DItem[]
  ): React.ReactElement[] => {
    return items.map(
      ({ title, image, id }: Slider3DItem): React.ReactElement => (
        <SwiperSlide key={id}>
          <CoachesResultsItem id={id} title={title} image={image} />
        </SwiperSlide>
      )
    );
  };

  const handleClickMoreButton = (e: any): void => {
    e.preventDefault();

    active ? setActive(false) : setActive(true);
  };

  return (
    <MainPageContainer>
      <Slider />
      {!authStatus && <RunningString />}
      {authStatus && role === 'user' && <MainBanners />}
      {!authStatus && (
        <NotAuthBannerText>
          Спортадиум - удобная система,
          <br /> позволяющая подобрать тренера,
          <br /> получать планы тренировок
          <br /> и советы по правильному питанию
          <br /> прямо в чате сервиса Sportadium,
          <br /> а также вести учет
          <br /> динамики веса и замеров тела.
          <br />
          <NotAuthBannerSpan>Попробуйте рабочий подход к достижению вашей цели!</NotAuthBannerSpan>
        </NotAuthBannerText>
      )}
      <InfoSection
        type={UserRoleType.Athlete}
        items={AthletesSection.items}
        title={AthletesSection.title}
        image={AthletesSection.image}
        stylesItems={AthletesSection.stylesItems}
        imageStyle={AthletesSection.imageStyle}
        btnText={AthletesSection.btnText}
        moreTextInfo={AthletesSection.moreTextInfo}
        visible={true}
        link={AthletesSection.link}
        auth={auth}
      />
      <Slider3D
        title={CoachesResultTitle.title}
        button={false}
        marginBottom={''}
        sliderHeight={CoachesResultStyle.sliderHeight}
        sliderWidth={CoachesResultStyle.sliderWidth}
        sliderBlur={CoachesResultStyle.sliderBlur}
        pagination={CoachesResultStyle.pagination}
      >
        {renderCoachesResultItems(CoachesResultItems.items)}
      </Slider3D>

      <PreviewComponent />

      {tariffs && <FlexSection items={tariffs} />}

      {coaches && (
        <Slider3D
          title={CoachesCatalogTitle.title}
          button={CoachesCatalogBtn}
          marginBottom={CoachesCatalogStyle.marginBottom}
          sliderHeight={CoachesCatalogStyle.sliderHeight}
          sliderWidth={CoachesCatalogStyle.sliderWidth}
          sliderBlur={CoachesCatalogStyle.sliderBlur}
          pagination={CoachesCatalogStyle.pagination}
        >
          {renderCoachesCatalogItems(coaches)}
        </Slider3D>
      )}

      <CoachCatalogTextWrapper>
        <CoachCatalogText>
          Занятия с персональным тренером
          в тренажерном зале – это возможность
          получить наиболее высокую эффективность
          работы, при этом избежать возможных ошибок.
        </CoachCatalogText>
        <MoreButton onClick={(e: any) => handleClickMoreButton(e)}>{active ? 'Свернуть' : 'Развернуть'}</MoreButton>
        {active && (
          <CoachCatalogMoreText>
            Подбор тренера — это сложная задача,
            ведь хочется найти человека,
            которому можно доверять
            и который сможет довести вас
            до желаемого результата.
            Именно поэтому, если вам нужен
            онлайн тренер, который составит план
            индивидуальных тренировок
            для тренажерного зала,
            то непременно стоит ознакомиться
            с базой персональных тренеров
            на сайте Спортадиум.
            <br />Наши тренеры опытны и
            компетентны, благодаря чему
            вы получите желаемый результат.
            Если вам покажется, что в процессе
            работы с тренером что-то идет не так,
            и хочется сменить наставника,
            то вы всегда сможете обратиться
            в нашу техническую поддержку для замены.
            <br />Мы прекрасно понимаем,
            что наши клиенты и тренеры,
            в первую очередь, – люди.
            Каждый имеет свой характер, взгляды и убеждения.
          </CoachCatalogMoreText>
        )}
      </CoachCatalogTextWrapper>

      {questions && <FrequentlyAskedQuestions items={questions} />}

      <FlexSectionInfoText>
        Сервис Спортадиум дает инструмент
        для удобного взаимодействия между
        тренерами и клиентами,
        которые хотят получить
        персональные программы тренировок
        в тренажерном зале и
        индивидуальные планы питания.
      </FlexSectionInfoText>
      <AboutUsSection title={AboutUsItem.title} text={AboutUsItem.text} />
    </MainPageContainer>
  );
};

const MainPageContainer = styled.div`
    display: flex;
    flex-direction: column;

    &:after {
        content: '';
        width: 976px;
        height: 800px;
        background: radial-gradient(50% 50% at 50% 50%, rgba(2, 0, 40, 0.25) 12.5%, rgba(106, 0, 182, 0.15) 68.75%, rgba(18, 0, 40, 0.25) 100%);
        position: absolute;
        bottom: -70px;
        right: -234px;
    }
`;

const NotAuthBannerText = styled.p`
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.28px;
    margin: 0 0 40px 0;

    @media (min-width: 375px) {
        font-size: 14px;
    }
`;

const NotAuthBannerSpan = styled.span`
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.28px;
    color: #13ffee;

    @media (min-width: 375px) {
        font-size: 16px;
    }
`;

export const FlexSectionInfoText = styled.p`
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.28px;
    margin: 0 0 100px 0;
    padding: 0 10px;

    @media (min-width: 375px) {
        font-size: 14px;
        padding: 0 30px;
    }
`;

const CoachCatalogTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const CoachCatalogText = styled(FlexSectionInfoText)`
    margin: 0 0 10px 0;
`;

const CoachCatalogMoreText = styled(FlexSectionInfoText)`
`;

const MoreButton = styled(ShowButton)``;

export const RegistrationButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
`;

export const RegistrationButton = styled(InfoButton)``;

export const getServerSideProps: GetServerSideProps = async () => {
  return {
    props: {}
  };
};

export default Home;
