import React from 'react';
import styled from 'styled-components';
import { Slider3DItem } from '@/interfaces/Slider3D';

const CoachesResultsItem: React.FC<Slider3DItem> = ({
  title,
  image,
}): React.ReactElement => {

  return (
    <CoachesResultsWrapper>
      <CoachesResultImageWrapper>
        <CoachesResultsImage src={`./slider3D/${image}`} alt="img" />
      </CoachesResultImageWrapper>
      <CoachesResultsText>Тренер: {title}</CoachesResultsText>
    </CoachesResultsWrapper>
  );
};

const CoachesResultsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: auto;
    background: #e4e4e433;
    border-radius: 16px;
    position: relative;
`;

const CoachesResultImageWrapper = styled.div`
    display: block;
    width: 100%;
    height: 100%;
    
    @media (min-width: 375px) {
        min-height: 200px;
    }
`

const CoachesResultsImage = styled.img`
    display: block;
    width: 100%;
    height: auto;
    min-height: 100%;
    object-fit: cover;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    transition: all 200ms ease-in-out;
`;

const CoachesResultsText = styled.h3`
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    padding: 10px 20px;
    width: 100%;
    border-radius: 0 0 16px 16px;
    max-height: 38px;
`;

export default CoachesResultsItem;
