import BannerProps from '@/interfaces/Banner';

const BannerLinks: BannerProps[] = [
  {
    title: 'Мои замеры',
    image: 'banner1.png',
  },
  {
    title: 'План тренировок',
    image: 'banner2.png',
  },
  {
    title: 'План питания',
    image: 'banner3.png',
  },
];

export default BannerLinks;
