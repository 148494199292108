import React, { useState } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { CoachPopupProps, CoachTariffs } from '@/store/Coaches/types';
import BaseButton from '@/components/ui/BaseButton';
// import { Swiper, SwiperSlide } from 'swiper/react';

const CoachPopup: React.FC<CoachPopupProps> = ({
                                                 id,
                                                 clickHandler,
                                                 clickChoiceCoach,
                                                 notAuthClick,
                                                 coach,
                                                 auth,
                                                 isFake,
                                                 isTest
                                               }): React.ReactElement => {
  const router = useRouter();
  const [status, setStatus] = useState<boolean>(false);

  const handleClickChoice = () => {
    clickChoiceCoach(
      id,
      coach?.user.id as number,
      coach?.image as string,
      coach?.user.name as string,
      coach?.user.surname as string
    );
    router.push('/account/worksheet');
  };

  const handleClickAvatar = () => {
    status ? setStatus(false) : setStatus(true);
  };

  const renderPlanItems = (
    tariffs: CoachTariffs[] | []
  ): React.ReactElement[] => {
    return tariffs.map(
      ({ id, name, image }: CoachTariffs): React.ReactElement => (
        <CoachPopupInfoPlanItem key={name} id={id}>
          {image && <CoachPopupInfoPlanItemImage src={image} alt="" />}
          {name}
        </CoachPopupInfoPlanItem>
      )
    );
  };

  return (
    <CoachPopupContainer>
      <CoachPopupWrapper>
        <CoachPopupCloseBtn onClick={(e: any) => clickHandler(e)} />
        <CoachPopupInfoWrapper>
          <CoachPopupImageWrapper onClick={() => handleClickAvatar()}>
            {coach?.image && <CoachPopupImage src={coach.image} />}
          </CoachPopupImageWrapper>
          <CoachPopupInfoTextWrapper>
            <CoachPopupInfoTitle>
              {coach?.user.name} {coach?.user.surname}
            </CoachPopupInfoTitle>
            <CoachPopupInfoAge>
              <span>Возраст:</span>
              {coach?.user.age} лет
            </CoachPopupInfoAge>
            <CoachPopupExperience>
              <span>Стаж:</span>
              {coach?.experience} лет
            </CoachPopupExperience>
            <CoachPopupInfoPlan>
              <CoachPopupInfoPlanTitle>Тренировки</CoachPopupInfoPlanTitle>
              <CoachPopupInfoPlanItemsWrapper>
                {coach?.tariffs && renderPlanItems(coach?.tariffs)}
              </CoachPopupInfoPlanItemsWrapper>
            </CoachPopupInfoPlan>
          </CoachPopupInfoTextWrapper>
        </CoachPopupInfoWrapper>
        <CoachPopupAboutInfoWrapper>
          <CoachPopupAboutInfoTitle>Обо мне:</CoachPopupAboutInfoTitle>
          <CoachPopupAboutInfoText>
            {coach?.description}
          </CoachPopupAboutInfoText>
        </CoachPopupAboutInfoWrapper>
        {isFake !== 1 && isTest !== 1 && (
          <CoachPopupBtn
            onClick={() => {
              auth ? handleClickChoice() : notAuthClick();
            }}
          >
            Познакомиться с тренером
          </CoachPopupBtn>
        )}
      </CoachPopupWrapper>

      {/*<CoachPopupSwiperWrapper status={status}>*/}
      {/*  <CoachPopupSwiperCloseBtn onClick={() => handleClickAvatar()} />*/}

      {/*  <CoachPopupSwiper slidesPerView={1} spaceBetween={0} loop={false}>*/}
      {/*    <CoachPopupSwiperSlide>1</CoachPopupSwiperSlide>*/}
      {/*    <CoachPopupSwiperSlide>2</CoachPopupSwiperSlide>*/}
      {/*    <CoachPopupSwiperSlide>3</CoachPopupSwiperSlide>*/}
      {/*    <CoachPopupSwiperSlide>4</CoachPopupSwiperSlide>*/}
      {/*  </CoachPopupSwiper>*/}
      {/*</CoachPopupSwiperWrapper>*/}
    </CoachPopupContainer>
  );
};

const CoachPopupContainer = styled.div`
    display: flex;
    align-items: center;
    min-height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 200;
`;

const CoachPopupWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 330px;
    max-width: 390px;
    padding: 20px 16px;
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 30px -1px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(1px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 0;
    z-index: 100;
    position: relative;
`;

const CoachPopupInfoWrapper = styled.div`
    display: flex;
    margin: 0 0 20px 0;
`;

const CoachPopupImageWrapper = styled.div`
    width: 128px;
    height: 209px;
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    margin: 0 12px 0 0;
    position: relative;

    &:after {
        content: '';
        width: 24px;
        height: 24px;
        display: block;
        background-image: url("/icons/Union.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: absolute;
        top: 8px;
        right: 5px;
        z-index: 100;
    }
`;

const CoachPopupImage = styled.img`
    display: block;
    max-width: 128px;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
`;

const CoachPopupInfoTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const CoachPopupInfoTitle = styled.h3`
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    margin: 0 0 10px 0;
`;

const CoachPopupInfoAge = styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.32px;
    margin: 0 0 5px 0;

    span {
        font-weight: 600;
        color: #13ffee;
        margin: 0 5px 0 0;
    }
`;

const CoachPopupExperience = styled(CoachPopupInfoAge)`
    margin: 0 0 10px 0;
`;

const CoachPopupInfoPlan = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 0 20px 0;
`;

const CoachPopupInfoPlanTitle = styled.h4`
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.7px;
    margin: 0 0 10px 0;
    color: #09f0eb;
`;

const CoachPopupInfoPlanItemsWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const CoachPopupInfoPlanItem = styled.p`
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.32px;
    padding: 0;
    margin: 0 7px 0 0;

    :last-child {
        margin: 0;
    }
`;

const CoachPopupInfoPlanItemImage = styled.img`
    display: block;
    width: 40px;
    height: 40px;
    object-fit: cover;
    margin: 0 8px 0 0;
`;

const CoachPopupAboutInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const CoachPopupAboutInfoTitle = styled(CoachPopupInfoPlanTitle)`
    font-size: 16px;
    text-align: center;
`;

const CoachPopupAboutInfoText = styled.p`
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.32px;
    margin: 0 0 20px 0;
    white-space: break-spaces;
`;

const CoachPopupBtn = styled(BaseButton)`
    width: 280px;
    height: 56px;
    font-size: 16px;
    margin: 0;
`;

export const CoachPopupCloseBtn = styled.button`
    width: 16px;
    height: 16px;
    background: transparent url('/assets/img/logo/close.svg') no-repeat center;
    border: none;
    outline: none;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 7px;
    z-index: 100;
`;

// const CoachPopupSwiperWrapper = styled.div<{ status: boolean }>`
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     background: rgba(0, 0, 0, 0.6);
//     backdrop-filter: blur(5px);
//     opacity: ${({ status }) => (status ? 1 : 0)};
//     position: absolute;
//     top: 0;
//     right: 0;
//     left: 0;
//     bottom: 0;
//     z-index: 101;
//     pointer-events: ${({ status }) => (status ? 'auto' : 'none')};
//
//     transition: opacity 300ms ease-in-out;
//     overflow: hidden;
// `;

// const CoachPopupSwiper = styled(Swiper)`
//     width: 100%;
//     max-height: 300px;
//     display: flex;
//     flex-direction: row;
//
//     .swiper-wrapper {
//         display: flex;
//     }
// `;

// const CoachPopupSwiperSlide = styled(SwiperSlide)`
//     width: 100%;
//     height: auto;
//     min-height: 300px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     background: black;
// `;
//
// const CoachPopupSwiperCloseBtn = styled(CoachPopupCloseBtn)`
//     right: 27px;
// `;

export default CoachPopup;
