import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import styled from 'styled-components';
import 'swiper/css';

SwiperCore.use([Pagination]);

const Slider: React.FC = (): React.ReactElement => {
  return (
    <SliderContainer>
      <Swiper
        modules={[Pagination]}
        pagination={false}
        spaceBetween={0}
        slidesPerView={1}
        loop={false}
      >
        <SwiperSlide>
          <SliderImage src="/assets/img/swiper/sportadium.png" alt="image" />
          <SliderTextContainer>
            <SliderTitle>Sportadium</SliderTitle>
            <SliderText>
              SPORTADIUM похудеть реально с
              <SliderTextSpan>онлайн консультациями от профессионалов</SliderTextSpan>
            </SliderText>
          </SliderTextContainer>
        </SwiperSlide>
      </Swiper>
    </SliderContainer>
  );
};

const SliderContainer = styled.div`
    display: flex;
    width: 100%;
    max-height: 204px;
    position: relative;

    .swiper {
        height: 100%;
        width: 100%;
        background: #020028;
    }

    .swiper-pagination {
        display: flex;
        position: absolute;
        top: 191px;
        right: 159px;
        z-index: 10;
    }

    .swiper-pagination-bullet {
        display: block;
        width: 8px !important;
        height: 8px !important;
        border: 1px solid #fff;
        border-radius: 50%;
        margin: 0 8px 0 0;
    }

    .swiper-pagination-bullet-active {
        background-color: #fff;
    }
`;

const SliderTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 44px;
    left: 185px;
    transition: all 200ms ease;

    @media (min-width: 375px) {
        left: 191px;
    }
`;

export const SliderTitle = styled.h2`
    font-size: 17px;
    font-weight: 800;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin: 0 0 10px 0;

    @media (min-width: 375px) {
        font-size: 22px;
        margin: 0 0 18px 0;
    }
`;

const SliderText = styled.p`
    display: flex;
    flex-wrap: wrap;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.05em;

    @media (min-width: 375px) {
        font-size: 14px;
    }
`;

const SliderTextSpan = styled.span`
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #13ffee;

    @media (min-width: 375px) {
        font-size: 14px;
    }
`;

const SliderImage = styled.img`
    display: block;
    width: 284px;
    height: 204px;
`;

export default Slider;
