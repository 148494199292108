import React, { useState } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import AboutSectionProps from '@/interfaces/AboutSection';
import TitleH2 from '@/components/ui/Title';
import { InfoButton, ShowButton } from '@/components/ui/InfoSection';


const AboutUsSection: React.FC<AboutSectionProps> = ({
                                                       title,
                                                       text
                                                     }): React.ReactElement => {
  const router = useRouter();
  const [active, setActive] = useState<boolean>(false);

  const handleClickButton = (): void => {
    router.push('/contacting');
  };

  const handleClickMoreButton = (e: any): void => {
    e.preventDefault();

    active ? setActive(false) : setActive(true);
  };

  return (
    <AboutUsContainer>
      <AboutUsTitle>{title}</AboutUsTitle>
      <AboutUsText>
        {text}
      </AboutUsText>
      <ShowButton onClick={(e: any) => handleClickMoreButton(e)}>{active ? 'Свернуть' : 'Развернуть'}</ShowButton>
      {active && (
        <AboutUsMoreText>
          Мы устали следовать такому алгоритму и решили, что хотим создать онлайн-площадку, где будут только проверенные
          тренеры и каждый сможет найти для себя наставника.
          <b>Спортадиум – готовое решение для ваших целей в фитнесе!</b>
          <br />Больше не придется сомневаться в опыте тренера: сотрудники Спортадиума уже всё проверили и собрали
          информацию
          о тренерах в каталоге.<br />Каждый тренер прошел этап собеседования и готов оказывать качественные услуги по
          разработке планов тренировок для фитнес-зала.
          Не покидая площадки, вы сможете не только быть на связи со своим наставником, но и вести учет своих
          показателей, вводя в удобную систему данные о своем весе и замерах разных частей тела. Прямо в личном кабинете
          заполняется полная анкета, чтобы не пришлось долго и нудно заполнять разные формы, рассказывая тренеру о себе.
          Достаточно заполнить один раз и можно приступать к работе!<br />Если по каким-либо причинам не удалось
          добиться
          идеального контакта с тренером, у вас всегда будет возможность выбрать другого. Это легко и быстро! Сотрудники
          технической поддержки Спортадиума будут на связи, чтобы вы не чувствовали себя одиноко.<br />
          Спортадиум будет развиваться, поэтому совсем скоро, помимо того функционала, который уже представлен, на
          сервисе появятся увлекательные графики, наглядно демонстрирующие прогресс, различная инфографика, статистика
          тренировок, динамика успехов и достижений и многое другое.
        </AboutUsMoreText>
      )}
      <AboutUsBtn margin={'0'} onClick={handleClickButton}>
        <span>Обратная связь</span>
      </AboutUsBtn>
    </AboutUsContainer>
  );
};

const AboutUsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 78px 0;
    z-index: 10;
`;

const AboutUsTitle = styled(TitleH2)`
    margin-bottom: 28px;
`;

const AboutUsText = styled.p`
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.8px;
    margin-bottom: 10px;
    padding: 0 13px;

    span {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.28px;
        color: #13ffee;
        margin: 0 0 0 5px;
    }

    @media (min-width: 390px) {
        font-size: 14px;
        padding: 0 23px;

        span {
            font-size: 13px;
        }
    }
`;

const AboutUsMoreText = styled(AboutUsText)``;

const AboutUsBtn = styled(InfoButton)<{ margin: string }>`
    margin-bottom: ${({ margin }) => margin || '0'};
    margin-top: 50px;
`;

export default AboutUsSection;
