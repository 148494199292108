import InfoSectionProps from '@/interfaces/InfoSection';
import UserRoleType from '@/enums/userRole';

export const AthletesSection: InfoSectionProps = {
  type: UserRoleType.Athlete,
  title: 'почему выбирают нас',
  image: 'rungirl-2.png',
  imageStyle: { width: '204px', height: '287px' },
  btnText:
    'Зарегистрируйтесь, чтобы получить качественные услуги фитнес тренера!<br/>После регистрации вы получите:<br/><br/>- чат с тренером, в котором можно обсудить все детали тренировочного процесса, отправить фото и видео;<br/><br/>- анкету для ввода данных о здоровье, питании и образе жизни. Тренер будет иметь доступ к этой информации;<br/><br/>- систему учета данных о динамике веса и замерах тела (объемы разных частей тела).',
  moreTextInfo:
    'В рамках бесплатной пробной недели, которая доступна сразу после регистрации, вы познакомитесь с тренером. Тренер изучит вашу анкету, составит планы первых тренировок и даст рекомендации по питанию.<br/>В ходе дальнейшей работы, тренер будет выходить с вами на связь, чтобы предоставлять новые планы тренировок по мере выполнения предыдущих, отвечать на возникающие вопросы и вносить корректировки на основании вашей обратной связи.<br/><br/>Если вы хотите получить максимальную отдачу от занятий и достичь своих спортивных целей, то индивидуальные тренировки с тренером – это именно то, что вам нужно.<br/>С помощью сервиса Спортадиум вы сможете найти онлайн-тренера, который составит планы тренировок и приведет вас к желаемому результату. ',
  stylesItems: {
    listStyle: {
      margin: '46px'
    },
    imageStyle: {
      margin: '59px'
    },
    textStyle: {
      margin: '20px'
    },
    buttonStyle: {
      margin: '20px'
    }
  },
  items: [
    {
      id: '1',
      text: 'Индивидуальный тренировочный план от профессиональных тренеров еженедельно.'
    },
    {
      id: '2',
      text: 'Предоставление индивидуальных рекомендаций по правильному питанию.'
    },
    {
      id: '3',
      text: 'Учет личных особенностей: травмы, ограничения, болезни.'
    },
    {
      id: '4',
      text: 'Коммуникация с тренером через встроенный чат.'
    }
  ],
  link: '/auth'
};

export default AthletesSection;
