import React from 'react';
import styled from 'styled-components';
import { CoachItem } from '@/store/Coaches/types';

const CoachesCatalog: React.FC<CoachItem> = ({
                                               id,
                                               experience,
                                               image,
                                               user,
                                               short_description
                                             }): React.ReactElement => {
  return (
    <CoachesCatalogContainer key={id} fake={user.is_fake}>
      <CoachesCatalogUserInfo>
        <CoachesImageWrapper>
          <CoachesImage src={image as string} />
        </CoachesImageWrapper>
        <CoachesCatalogTextWrapper>
          <CoachesCatalogTitle>{user.name + ' ' + user.surname}</CoachesCatalogTitle>
          <CoachesCatalogText>
            <span>Стаж:</span>
            <br />
            более {experience} лет
          </CoachesCatalogText>
          {
            user.is_fake !== 0 && (
              <IsFakeCoachText>
                Мест нет
              </IsFakeCoachText>
            )
          }
        </CoachesCatalogTextWrapper>
      </CoachesCatalogUserInfo>
      {short_description && (
        <CoachesCatalogMoreText>
          {short_description}
        </CoachesCatalogMoreText>
      )}
    </CoachesCatalogContainer>
  );
};

const CoachesCatalogContainer = styled.div<{ fake: number | null }>`
    display: flex;
    flex-direction: column;
    padding: ${({ fake }) => fake === 1 ? ' 52px 16px 24px 16px' : '24px 16px'};
`;

const CoachesImageWrapper = styled.div`
    width: 96px;
    height: 96px;
    border-radius: 50%;
    margin: 0 15px 0 0;
    position: relative;
    z-index: 100;
`;

const CoachesCatalogUserInfo = styled.div`
    display: flex;
`;

const CoachesImage = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
`;

const CoachesCatalogTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const CoachesCatalogTitle = styled.h3`
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
`;

const CoachesCatalogText = styled.p`
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;

    span {
        font-size: 14px;
        font-weight: 700;
        color: #13ffee;
    }
`;

const CoachesCatalogMoreText = styled(CoachesCatalogText)`
    font-size: 12px;
    white-space: pre-line;
    word-break: break-word;
    padding: 10px 0 0 0;
`;

export const IsFakeCoachText = styled.span`
    width: 120px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: white;
    padding: 10px 0;
    background: linear-gradient(90deg, #4b80fe 0%, #8131ff 100%);
    position: absolute;
    top: 0;
    right: 0;
`;

export default CoachesCatalog;
