import Slider3DProps from '@/interfaces/Slider3D';

export const CoachesResultTitle = {
  title: 'Результаты наших тренеров'
};

export const CoachesResultStyle = {
  sliderHeight: '320px',
  sliderWidth: '280px',
  sliderBlur: '40px',
  pagination: true
};

const CoachesResultItems: Slider3DProps = {
  items: [
    {
      id: '0',
      title: 'Артём Крысин',
      image: 'new-result1.jpg'
    },
    {
      id: '1',
      title: 'Артём Крысин',
      image: 'new-result2.jpg'
    },
    {
      id: '2',
      title: 'Артём Крысин',
      image: 'new-result3.jpg'
    },
    {
      id: '3',
      title: 'Константин Иванидзе',
      image: 'new-result4.jpg'
    },
    {
      id: '4',
      title: 'Константин Иванидзе',
      image: 'new-result5.jpg'
    },
    {
      id: '5',
      title: 'Артём Крысин',
      image: 'new-result6.jpeg'
    },
    {
      id: '6',
      title: 'Артём Крысин',
      image: 'new-result7.jpeg'
    },
    {
      id: '7',
      title: 'Артём Крысин',
      image: 'new-result8.jpeg'
    },
    {
      id: '8',
      title: 'Константин Иванидзе',
      image: 'new-result9.jpg'
    },
    {
      id: '9',
      title: 'Константин Иванидзе',
      image: 'new-result10.jpg'
    },
    {
      id: '10',
      title: 'Константин Иванидзе',
      image: 'new-result11.jpg'
    },
    {
      id: '11',
      title: 'Константин Иванидзе',
      image: 'new-result12.jpg'
    }
  ]
};

export default CoachesResultItems;
