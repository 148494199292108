import React from 'react';
import styled from 'styled-components';
import { SliderTitle } from '@/components/ui/SliderContainer';

const RunningString: React.FC = (): React.ReactElement => {
  return (
    <RunningStringWrapper>
      <Marquee className="marquee">
        <span>Попробуйте неделю бесплатно</span>
        <span>Попробуйте неделю бесплатно</span>
      </Marquee>
    </RunningStringWrapper>
  );
};

const RunningStringWrapper = styled.div``;

const Marquee = styled(SliderTitle)`
    width: 100%;
    white-space: nowrap;
    gap: 20px;
    padding: 13px 0;
    background: #08e8de;
    overflow: hidden;

    span {
        display: inline-block;
        color: #fff;
        padding-left: 20%;
        -webkit-animation: scroll 4s infinite linear;
        -moz-animation: scroll 4s infinite linear;
        animation: scroll 4s infinite linear;
    }

    @keyframes scroll {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(-100%)
        }
    }
`;
export default RunningString;