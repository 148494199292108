import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectCoverflow } from 'swiper';
import TitleH2 from '@/components/ui/Title';
import { useRouter } from 'next/router';
import { RegistrationButton, RegistrationButtonWrapper } from '@/pages/index';

SwiperCore.use([EffectCoverflow]);

const PreviewComponent: FC = (): ReactElement => {
  const router = useRouter();

  const handleClickButton = () => {
    router.push('/auth');
  };

  return (
    <PreviewSection>
      <PreviewSectionTitle>Возможности личного кабинента пользователя</PreviewSectionTitle>
      <PreviewSectionSwiper effect={'coverflow'}
                            grabCursor={true}
                            centeredSlides={true}
                            initialSlide={1}
                            slidesPerView={'auto'}
                            coverflowEffect={{
                              rotate: 0,
                              stretch: 160,
                              scale: 0.8,
                              depth: 100,
                              modifier: 1,
                              slideShadows: false
                            }}>
        <PreviewSwiperSlide>
          <PreviewSlideImageWrapper>
            <PreviewSlideImage src={'/gifs/health_preview.gif'} alt={'health preview'} />
          </PreviewSlideImageWrapper>
        </PreviewSwiperSlide>
        <PreviewSwiperSlide>
          <PreviewSlideImageWrapper>
            <PreviewSlideImage src={'/gifs/health_preview.gif'} alt={'health preview'} />
          </PreviewSlideImageWrapper>
        </PreviewSwiperSlide>
        <PreviewSwiperSlide>
          <PreviewSlideImageWrapper>
            <PreviewSlideImage src={'/gifs/messenger_preview.gif'} alt={'health preview'} />
          </PreviewSlideImageWrapper>
        </PreviewSwiperSlide>
      </PreviewSectionSwiper>


      <RegistrationButtonWrapper>
        <RegistrationButton onClick={() => handleClickButton()}>
          <span>Попробовать неделю бесплатно</span>
        </RegistrationButton>
      </RegistrationButtonWrapper>
    </PreviewSection>
  );
};

const PreviewSection = styled.section`
    display: block;
    margin: 50px 0;
`;

const PreviewSectionTitle = styled(TitleH2)`
`;

const PreviewSectionSwiper = styled(Swiper)`
    margin: 50px 0;
`;

const PreviewSwiperSlide = styled(SwiperSlide)`
    width: 250px;
    height: 440px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 165px;
    color: black;
    background: #ffffff99;
    border: 1px solid #ffffff;
    border-radius: 16px;
    backdrop-filter: blur(20px);
    position: relative;
`;

const PreviewSlideImageWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 16px;
    overflow: hidden;
`;

const PreviewSlideImage = styled.img`
    width: 100%;
    max-width: 100%;
    height: auto;
    min-height: 100%;
    object-fit: cover;
`;


export default PreviewComponent;