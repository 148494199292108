export const CoachesCatalogTitle = {
  title: 'КАТАЛОГ ТРЕНЕРОВ',
};

export const CoachesCatalogStyle = {
  marginBottom: '35px',
  sliderHeight: '144px',
  sliderWidth: '248px',
  sliderBlur: '30px',
  pagination: false,
};

export const CoachesCatalogBtn = true;

