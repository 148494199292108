import React from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { Swiper } from 'swiper/react';
import SwiperCore, { EffectCoverflow } from 'swiper';
import TitleH2 from '@/components/ui/Title';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import { InfoButton } from '@/components/ui/InfoSection';
import { Slider3DList } from '@/interfaces/Slider3D';

SwiperCore.use([EffectCoverflow]);

const Slider3D: React.FC<Slider3DList> = ({
                                            title,
                                            button,
                                            marginBottom,
                                            sliderHeight,
                                            sliderWidth,
                                            sliderBlur,
                                            pagination,
                                            children
                                          }): React.ReactElement => {
  const router = useRouter();

  const handleClickCatalog = (): void => {
    router.push('/catalog');
  };

  return (
    <Slider3DContainer
      sliderHeight={sliderHeight}
      sliderBlur={sliderBlur}
      sliderWidth={sliderWidth}
      pagination={pagination}
    >
      <Slider3DTitle margin={marginBottom}>{title}</Slider3DTitle>
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        initialSlide={1}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 0,
          stretch: 160,
          scale: 0.8,
          depth: 100,
          modifier: 1,
          slideShadows: false
        }}
        pagination={{ el: '.swiper-pagination', clickable: true }}
        modules={[EffectCoverflow]}
      >
        {children}
        <div className="swiper-pagination" />
      </Swiper>

      {button && (
        <Slider3DButton margin={'25px'} onClick={() => handleClickCatalog()}>
          <span>Перейти в каталог</span>
        </Slider3DButton>
      )}
    </Slider3DContainer>
  );
};

const Slider3DContainer = styled.div<{
  sliderHeight: string;
  sliderWidth: string;
  sliderBlur: string;
  pagination: boolean;
}>`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 390px;
    margin: 0 auto 53px auto;
    position: relative;

    .swiper-wrapper {
        width: 100%;
        max-width: 320px;
        margin-bottom: 40px;

        @media (min-width: 375px) {
            max-width: 390px;
        }
    }

    .swiper-3d {
        perspective: 1200px;
        overflow: hidden;
    }

    .swiper-slide {
        display: flex;
        background: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(${({ sliderBlur }) => sliderBlur});
        border: 1px solid #ffffff;
        border-radius: 16px;
        width: 100%;
        height: auto;
        max-width: 240px;

        @media (min-width: 375px) {
            max-width: 280px;
        }

    }

    .swiper-slide-active {
        opacity: 1;
    }

    .swiper-pagination {
        display: flex;
        width: 100%;
        justify-content: center;
        opacity: ${({ pagination }) => (pagination ? '1' : '0')};
        pointer-events: ${({ pagination }) => (pagination ? 'auto' : 'none')};
    }

    .swiper-pagination-bullet {
        display: block;
        width: 9px;
        height: 9px;
        background-color: #fff;
        border-radius: 50%;
        margin: 0 5px;
        opacity: 0.4;
    }

    .swiper-pagination-bullet-active {
        opacity: 1;
    }

    &:before {
        content: '';
        width: 458px;
        height: 458px;
        background: radial-gradient(50% 50% at 50% 50%, rgba(2, 0, 40, 0.2) 0%, rgba(106, 0, 182, 0.2) 44.79%, rgba(18, 0, 40, 0.2) 100%);
        position: absolute;
        top: -40px;
        right: -30px;
    }
`;

const Slider3DTitle = styled(TitleH2)<{ margin: string }>`
    width: 215px;
    margin-bottom: ${({ margin }) => margin || '40px'};
`;

const Slider3DButton = styled(InfoButton)<{ margin: string }>`
    margin-top: ${({ margin }) => margin || '0'};
`;

export default Slider3D;
