import * as React from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import BannerProps from '@/interfaces/Banner';
import BannerLinks from '@/constants/MainBanner';

const MainBanners: React.FC = (): React.ReactElement => {
  const router = useRouter();

  const handleClickBanner = (): void => {
    router.push('/account');
  };

  const renderBanner = (links: BannerProps[]): React.ReactElement[] => {
    return links.map(
      ({ title, image }: BannerProps): React.ReactElement => (
        <BannerListItem
          onClick={() => handleClickBanner()}
          key={title}
          style={{ backgroundImage: `url(/assets/img/mainBanners/${image})` }}
        >
          <BannerLink>
            <BannerTitle>{title}</BannerTitle>
          </BannerLink>
        </BannerListItem>
      )
    );
  };

  return (
    <BannerList>{renderBanner(BannerLinks)}</BannerList>
  );
};

const BannerList = styled.ul`
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    margin: 60px 0 75px 0;
    position: relative;

    &:before {
        content: '';
        width: 635px;
        height: 635px;
        background: radial-gradient(50% 50% at 50% 50%, rgba(2, 0, 40, 0.2) 0%, rgba(106, 0, 182, 0.2) 56.25%, rgba(18, 0, 40, 0.2) 100%);
        position: absolute;
        top: -120px;
    }
`;

const BannerListItem = styled.li`
    position: relative;
    padding: 0 0 0 18px;
    margin: 0 0 16px 0;
    width: 100%;
    min-height: 104px;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 16px;

nth-child(3) {
    margin: 0;
}
`;

const BannerLink = styled.a`
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: radial-gradient(
            56.1% 150.52% at 78.2% 52.4%,
            rgba(3, 0, 63, 0) 0%,
            rgba(3, 0, 54, 0.6) 100%
    );
    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.4));
    min-height: 104px;
`;

const BannerTitle = styled.h2`
    position: relative;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.02em;
    z-index: 10;
    padding: 0 0 0 18px;
`;

export default MainBanners;
